/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/email.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
const Email = () => {

  return (
    <section className={styles.email}>
      <span className={styles.modalHeader}><Title title="Complete this form." /></span>
      <p className={styles.instructionsModal}>Enter a new email address and select 'Submit' to continue.</p>
      <div className={styles.center}>
        <form className={styles.form}>
          <div>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="email"
              className={styles.formControl}
            />
          </div>
          <div className={styles.arrowButton}>
            <input
              type="submit"
              value="submit"
              className={styles.submit}
              action="/email"
            />
          </div>
        </form>
      </div>
    </section>
  )
}

export default Email
